import React, { useState } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import BildHeader from "../../images/services/screening/header.jpg"
import BildOben from "../../images/services/screening/oben.png"
import SubNavigation from "../../components/SubNavServices"
import Termin from "../../components/Termin"

const ScreeningTest: React.FC = () => {
  const siteTitle = "Screening-Test – Dienstleistungen"
  const [isSubMenuOpen, setSubMenuOpen] = useState(false)

  return (
    <Layout title={siteTitle}>
      <SEO title={siteTitle} />
      <section className="wrapper">
        <div className="headerImage">
          <img src={BildHeader} />
        </div>
      </section>
      <Termin />
      <SubNavigation
        isSubMenuOpen={isSubMenuOpen}
        setSubMenuOpen={setSubMenuOpen}
        activeMenuText="Screening-Test"
      />
      <section className="wrapper greyColor content">
        <h1>Screening-Test</h1>
        <h2 className="contentSubtitle">SCHWARZ Optik – Seit 1992 in Heiden</h2>
        <div className="contentColumns">
          <div className="contentColumn">
            <div className="imageCollection">
              <div className="imageRow">
                <img src={BildOben} />
              </div>
            </div>
            <p className="imageCaption">
              Screening hilft, Rückschlüsse auf das gesamte System Auge zu
              ziehen. Gesundheit bis ins hohe Alter gewinnt bei der älter
              werdenden Bevölkerung an Bedeutung. Der Optometrist kann mittels
              eines guten Augenscreenings dazu beitragen.
            </p>
          </div>
          <div className="contentColumn">
            <p>
              Ergänzend zu den Untersuchungen für Ihre individuelle
              Korrekturbestimmung und Kontaktlinsenverträglichkeit bietet Ihnen
              SCHWARZ Optik verschiedene Screening-Tests: Prüfung des Farbsehens
              sowie berührungslose Augeninnendruck- (Tonometrie) und
              Gesichtsfeld- Messung (Perimetrie).
            </p>
            <p>
              Selbstverständlich gehören Atteste zur Erteilung des Lernfahr- und
              Führerausweises und Folge- kontrollteste, wie es die MFK verlangt,
              auch dazu.
            </p>
            <p>
              Beim Erkennen medizinischer Veränderungen empfehlen wir Ihnen den
              Besuch des Augenarztes.
            </p>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ScreeningTest
